<template>
  <BaseDialog :error="error" :dialog="dialog" @close="close($event)">
    <template v-slot:title> {{ $tc("title") }} </template>
    <template v-slot:text>
      <div class="mb-5">
        {{ $tc("description") }}
      </div>

      <DynamicForm
        :fields="fields"
        :formData.sync="formData"
        @is-valid-changed="updateFormValid($event)"
        ref="DynamicForm"
      />
    </template>
    <template v-slot:actionsRight>
      <BaseButton
        :loading="isSaving"
        :text="true"
        @click="close()"
        color="black"
      >
        {{ $t("label.cancel") }}
      </BaseButton>
      <BaseButton :disabled="!formValid" :loading="isSaving" @click="save()">
        {{ $t("label.save") }}
      </BaseButton>
    </template>
  </BaseDialog>
</template>

<script>
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDialog from "@/components/base/BaseDialog.vue";
import DynamicForm from "@/components/base/DynamicForm.vue";
import gql from "graphql-tag";

const initialData = () => ({
  error: null,
  fields: [],
  formData: {},
});

export default {
  name: "connection-request-dialog",
  components: {
    BaseButton,
    BaseDialog,
    DynamicForm,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
    },
  },

  data() {
    return {
      error: null,
      isSaving: false,
      fields: [],
      formData: {},
      formValid: false,
    };
  },

  computed: {},

  watch: {
    dialog(val) {
      // reset the form to it's original state and reset the validation when dialog closes
      if (!val) {
        this.$emit("update:item", null);
        this.resetData();
        this.$refs.DynamicForm.resetForm();
      } else {
        this.setFormFields();
        this.setFormData(this.item);
      }
    },
  },

  created() {},

  mounted() {},

  methods: {
    close() {
      this.$emit("update:dialog", false);
    },

    resetData() {
      // reset the data to it's initial state
      const data = initialData();
      Object.keys(data).forEach((k) => (this[k] = data[k]));
    },

    setFormFields() {
      // set the field from the config from the DB
      const fieldsJson = `
			[
					{
							"cols": 8,
							"component": "v-text-field",
							"label": "Naam",
							"model": "name",
							"rules": [
									"required"
							]
					},
					{
							"component": "v-autocomplete",
							"model": "type",
							"label": "Type",
							"cols": 4,
							"rules": [
									"required"
							],
							"query": "query allFieldChoices {  allFieldChoices(appName: \\"users\\", modelName: \\"Partner\\", fieldName: \\"type\\") {    fieldChoices {    value    }    defaultValue  }}",
							"itemKey": "value",
							"itemValue": "value",
							"resultKey": "allFieldChoices",
							"listKey": "fieldChoices",
							"multiple": false
					},
					{
							"component": "v-autocomplete",
							"model": "tenants",
							"label": "Tenants",
							"cols": 12,
							"rules": [
									"required"
							],
							"query": "query tenants { tenants(orderBy: [\\"tenantName\\"]) { edges { node { id tenantName } } } }",
							"itemKey": "id",
							"itemValue": "tenantName",
							"resultKey": "tenants",
							"multiple": true
					},
					{
              "component": "v-autocomplete",
              "model": "taps",
              "label": "Taps",
              "cols": 12,
              "rules": [
                  "required"
              ],
              "query": "query allTaps { allTaps(orderBy: [\\"name\\"]) { edges { node { id externalId name } } } }",
              "itemKey": "id",
              "itemValue": "name",
              "resultKey": "allTaps",
              "multiple": true,
							"condition": "formData?.type === 'SOFTWARE'"
          }
			]
      `;
      this.fields = JSON.parse(fieldsJson);
    },

    setFormData(item) {
      // edit
      if (item) {
        this.formData = {
          name: this.item.name,
          taps: this.item?.taps?.edges.map((edge) => edge.node.id),
          tenants: this.item?.tenants?.edges.map((edge) => edge.node.id),
          type: this.item.type,
        };
      }
      // add
      else {
        const formDataJson = `
        {
					"type": null,
          "name": null,
          "taps": null,
          "tenants": null
        }
        `;
        this.formData = JSON.parse(formDataJson);
      }
    },

    updateFormValid(isValid) {
      this.formValid = isValid;
    },

    save() {
      if (!this.formValid) {
        return;
      }

      this.error = null;
      this.isSaving = true;

      var payload = this.formData;

      if (this.item) {
        payload.id = this.item.id;

        this.$apollo
          .mutate({
            mutation: gql`
              mutation updatePartner($input: UpdatePartnerInput!) {
                updatePartner(input: $input) {
                  partner {
                    id
                  }
                }
              }
            `,
            variables: {
              input: payload,
            },
          })
          .then((response) => {
            this.$emit("changed", response);
            this.close();

            const payload = {
              color: "success",
              message: `Partner successfully edited`,
            };
            this.$store.dispatch("snackbar/showMessage", payload);
          })
          .catch((error) => {
            console.log(error);

            this.error = error.graphQLErrors
              .map((error) => error.message)
              .join(", ");
          })
          .finally(() => {
            this.isSaving = false;
          });
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation createPartner($input: CreatePartnerInput!) {
                createPartner(input: $input) {
                  partner {
                    id
                  }
                }
              }
            `,
            variables: {
              input: payload,
            },
          })
          .then((response) => {
            this.$emit("changed", response);
            this.close();

            const payload = {
              color: "success",
              message: `Partner successfully added`,
            };
            this.$store.dispatch("snackbar/showMessage", payload);
          })
          .catch((error) => {
            console.log(error);

            this.error = error.graphQLErrors
              .map((error) => error.message)
              .join(", ");
          })
          .finally(() => {
            this.isSaving = false;
          });
      }
    },
  },
};
</script>

<i18n>
  {
    "en": {
      "title": "Partner",
      "description": "Partners are essentially a group of tenants which a partner user has access to. We distinguish two partner types: consultant and software. The only difference is an extra filter which applies to software partners. They only get access to the standard reports and sandboxes that belong to the tenant tap combination which is selected."
    },
    "nl": {
      "title": "Partner",
      "description": "Partners zijn in wezen een groep tenants waar een partnergebruiker toegang toe heeft. We onderscheiden twee type partners: consultant en software. Het enige verschil is een extra filter dat van toepassing is op softwarepartners. Zij hebben alleen toegang tot de standaardrapporten en sandboxes die behoren tot de geselecteerde combinatie van tenant en tap."
    },
    "de": {
      "title": "Partner",
      "description": "Partner sind im Wesentlichen eine Gruppe von Tenants, auf die ein Partnerbenutzer Zugriff hat. Wir unterscheiden zwei Partnertypen: Berater und Software. Der einzige Unterschied ist ein zusätzlicher Filter, der für Softwarepartner gilt. Sie haben nur Zugriff auf die Standardberichte und Sandboxes, die zur ausgewählten Kombination aus Tenant und Tap gehören."
    }
  }
</i18n>
