<template>
  <v-app id="pbiportal">
    <!-- Notification -->
    <Snackbar></Snackbar>

    <!-- Top App Bar -->
    <v-app-bar
      v-if="isLoggedIn && !disableAppBar"
      v-show="isLoggedIn"
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      dark
      color="primary"
      dense
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title
        v-if="$vuetify.breakpoint.mdAndUp"
        style="width: 300px"
        class="ml-0 pl-4"
      >
        <span
          v-if="isLoggedIn && me.tenant"
          class="hidden-sm-and-down font-weight-bold"
        >
          {{ me.tenant.theme.themeName }}
        </span>
        <span v-else class="hidden-sm-and-down font-weight-bold">
          TapTarget
        </span>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-toolbar-title
        v-if="appBarTitle"
        style="width: 600px"
        class="ml-0 pl-4 font-weight-bold"
      >
        <span>
          {{ appBarTitle }}
        </span>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
        :color="overallStatus?.color"
        @click="$router.push({ name: 'status' })"
        class="ml-5"
        rounded
        small
        v-if="overallStatus && overallStatus?.nameEn != 'Operational'"
      >
        <v-icon left small> {{ overallStatus?.icon }} </v-icon>
        {{ overallStatus?.[`name${capitalizeFirstLetter($i18n.locale)}`] }}
      </v-btn>

      <v-btn
        @click="$router.push({ name: 'reports', query: { tab: '1' } })"
        class="ml-5"
        color="info"
        rounded
        small
        v-if="allUserTasks?.tasksRunning?.totalCount > 0"
      >
        <v-progress-circular
          :size="14"
          :width="1"
          class="mr-2"
          color="white"
          indeterminate
        ></v-progress-circular>
        {{ $t("exporting") }}
      </v-btn>

      <v-btn
        @click="$router.push({ name: 'reports', query: { tab: '1' } })"
        class="ml-5"
        color="success"
        rounded
        small
        v-else-if="allUserTasks?.tasksSuccess?.totalCount"
      >
        <v-icon small left>check_circle</v-icon>
        {{ $t("exportDone") }}
      </v-btn>

      <v-btn
        @click="$router.push({ name: 'settings' })"
        class="ml-5"
        color="error"
        rounded
        small
        v-if="!me.passed2fa"
      >
        <v-icon small left>warning</v-icon>
        {{ $t("label.enable2fa") }}
      </v-btn>

      <!-- Notifications -->
      <Notifications
        :loggedOutAsOtherUserCounter="loggedOutAsOtherUserCounter"
        v-if="isLoggedIn"
      />

      <!-- Translations -->
      <v-menu
        offset-y
        origin="center center"
        :nudge-bottom="10"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon> translate </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            :key="item.locale"
            @click="changeLocale(item.locale)"
            v-for="item in locales"
          >
            <v-list-item-title>{{ item.localeName }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- Logo -->
      <v-btn @click="$router.push({ name: 'home' })" icon large>
        <v-avatar size="32px" item>
          <v-img
            v-if="isLoggedIn && me.tenant"
            :src="me.tenant.theme.themeLogoPath"
          ></v-img>
          <v-img v-else src="/static/taptarget-logo-white.png"></v-img>
        </v-avatar>
      </v-btn>
    </v-app-bar>

    <!-- Navigation menu -->
    <v-navigation-drawer
      v-show="isLoggedIn"
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <v-list v-if="me">
        <v-list-item>
          <v-list-item-avatar color="primary">
            <v-icon dark>person</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              >{{ me.firstName }} {{ me.lastName }}</v-list-item-title
            >
            <v-list-item-subtitle>{{ me.email }}</v-list-item-subtitle>
            <v-list-item-subtitle>
              <v-chip
                class="mr-1"
                color="success"
                label
                v-if="me.isPartnerUser"
                x-small
                >Partner</v-chip
              >
              <v-chip color="error" label v-if="me.isSuperuser" x-small
                >Superuser</v-chip
              >
              <v-chip
                color="info"
                label
                v-if="
                  !me.isSuperuser &&
                  hasPermission('dashboards.view_usermapping')
                "
                x-small
                >Admin</v-chip
              >
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list dense>
        <!-- home -->
        <v-list-item :to="{ name: 'home' }" color="primary" exact>
          <v-list-item-action>
            <v-icon>home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("label.home") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- reports -->
        <v-list-item
          :to="{ name: 'reports' }"
          color="primary"
          exact
          v-if="
            hasPermission('dashboards.view_embedded_report') && me.passed2fa
          "
        >
          <v-list-item-action>
            <v-icon>bar_chart</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $tc("label.report", 2) }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-tooltip bottom v-if="allUserTasks?.tasksSuccess?.totalCount > 0">
              <template v-slot:activator="{ on }">
                <v-chip
                  :class="['mt-1', 'mr-1']"
                  :color="'success'"
                  small
                  v-on="on"
                >
                  <v-icon left small> check_circle </v-icon>
                  {{ allUserTasks.tasksSuccess.totalCount }}
                </v-chip>
              </template>
              <span> {{ $t("exportDone") }} </span>
            </v-tooltip>
            <v-tooltip bottom v-if="allUserTasks?.tasksRunning?.totalCount > 0">
              <template v-slot:activator="{ on }">
                <v-chip :class="['mt-1']" :color="'info'" small v-on="on">
                  <v-progress-circular
                    :size="14"
                    :width="1"
                    class="ml-n1 mr-1"
                    color="white"
                    indeterminate
                  ></v-progress-circular>
                  {{ allUserTasks.tasksRunning.totalCount }}
                </v-chip>
              </template>
              <span> {{ $t("exporting") }} </span>
            </v-tooltip>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          :to="{ name: 'taps' }"
          color="primary"
          exact
          v-if="
            (hasPermission('integrations.view_connection') ||
              hasPermission('integrations.view_run')) &&
            me.passed2fa
          "
        >
          <v-list-item-action>
            <v-icon>sync_alt</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $tc("label.tap", 2) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'targets' }"
          color="primary"
          exact
          v-if="me.isSuperuser"
        >
          <v-list-item-action>
            <v-icon>adjust</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $tc("label.target", 2) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'add-ons' }"
          color="primary"
          exact
          v-if="
            (hasPermission('integrations.view_connection') ||
              hasPermission('integrations.view_run')) &&
            me.passed2fa &&
            !me.isPartnerUser
          "
        >
          <v-list-item-action>
            <v-icon>add_circle</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $tc("label.addOn", 2) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'control-panel' }"
          color="primary"
          exact
          v-if="
            hasPermission('integrations.view_connection') &&
            me.passed2fa &&
            (me.isPartnerUser || me.isSuperuser)
          "
        >
          <v-list-item-action>
            <v-icon>apps</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{
              $tc("label.controlPanel", 1)
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <!-- users -->
        <v-list-item
          :to="{ name: 'users' }"
          color="primary"
          exact
          v-if="hasPermission('users.view_customuser') && me.passed2fa"
        >
          <v-list-item-action>
            <v-icon>contacts</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $tc("label.user", 2) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- settings -->
        <v-list-item :to="{ name: 'settings' }" color="primary" exact>
          <v-list-item-action>
            <v-icon>settings</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $tc("label.setting", 2) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'status' }" color="primary" exact>
          <v-list-item-action>
            <v-icon>lightbulb</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $tc("label.status", 2) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn block text outlined rounded color="error" @click="logout()">{{
            $t("label.logout")
          }}</v-btn>
        </div>
        <center class="grey--text subtitle-2 mb-1">
          v1.1.25 &copy; TapTarget
        </center>
      </template>
    </v-navigation-drawer>

    <!-- add to load the router -->
    <v-main fill-height>
      <v-alert v-if="isLoggedInAsOtherUser" color="error lighten-2" dark>
        {{ $t("loggedInAs") }} {{ me.email }}
        <v-btn dark class="ml-2" @click="logoutAsOtherUser"
          >{{ $t("label.close") }}
        </v-btn>
      </v-alert>

      <router-view
        :loggedOutAsOtherUserCounter="loggedOutAsOtherUserCounter"
      ></router-view>
      <!-- v-if is necessary to only create the dialog for 2fa users -->
      <TfaPersistentDialog :dialog="tfaDialog" v-if="tfaDialog" />
    </v-main>
  </v-app>
</template>

<script>
import helper from "@/utils/helper.js";
import Notifications from "@/components/base/Notifications.vue";
import Snackbar from "@/components/base/Snackbar.vue";
import TfaPersistentDialog from "@/components/users/TfaPersistentDialog.vue";

export default {
  name: "App",
  components: {
    Notifications,
    Snackbar,
    TfaPersistentDialog,
  },

  apollo: {},

  data: () => ({
    drawer: false,
    isLoggingOutAsOtherUser: false,
    loggedOutAsOtherUserCounter: 0,
    locales: [
      {
        locale: "en",
        localeName: "English",
      },
      {
        locale: "nl",
        localeName: "Nederlands",
      },
      {
        locale: "de",
        localeName: "Deutsch",
      },
    ],
  }),
  computed: {
    isLoggedIn() {
      return this.$store.state.user.loggedIn;
    },
    isLoggedInAsOtherUser() {
      return this.$store.state.user.loggedInAsOtherUser;
    },
    today() {
      var _today = new Date();
      _today.setHours(23, 59, 59, 59); // remove time

      return _today;
    },
    me() {
      return this.$store.state.user.me || {};
    },
    languages() {
      return Object.keys(this.$i18n.messages);
    },
    disableAppBar() {
      return this.$store.state.app.disableAppBar;
    },
    appBarTitle() {
      return this.$store.state.app.appBarTitle;
    },
    allUserTasks() {
      return this.$store.state.user.allUserTasks;
    },
    overallStatus() {
      return this.$store.state.user.overallStatus;
    },
    tfaDialog() {
      if (
        this.me.identityProvider == "TAPTARGET" &&
        this.isLoggedIn &&
        !this.me.passed2fa
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    isLoggedIn(val) {
      if (val) {
        // fetch me
        this.fetchMe();
      }
    },
    isLoggedInAsOtherUser(val) {
      if (val) {
        this.fetchMe();
      }
    },
  },
  created() {
    // copy hasPermission from helper
    this.hasPermission = helper.hasPermission;
    this.capitalizeFirstLetter = helper.capitalizeFirstLetter;

    if (this.isLoggedIn) {
      // fetch me
      this.fetchMe();
      this.fetchOverallStatus();
      this.fetchAllUserTasks();
      this.interval = setInterval(() => this.fetchAllUserTasks(), 60000);
    }
  },
  mounted() {},
  methods: {
    fetchMe() {
      this.$store
        .dispatch("user/fetchMe")
        .then(() => {
          try {
            this.$vuetify.theme.themes.light.primary =
              this.$store.state.user.me.tenant.theme.primaryColor;
            this.$gtag.set("user_properties", {
              user_email: this.$store.state.user.me.email,
              user_tenant: this.$store.state.user.me.tenant.tenantName,
            });
          } catch (err) {
            //console.log(err);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchOverallStatus() {
      this.$store
        .dispatch("user/fetchOverallStatus")
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    },

    fetchAllUserTasks() {
      this.$store
        .dispatch("user/fetchAllUserTasks")
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    },

    changeLocale(locale) {
      localStorage.setItem("locale", locale);
      this.$i18n.locale = locale;
      this.$router.go(this.$router.currentRoute);
    },

    logoutAsOtherUser() {
      this.isLoggingOutAsOtherUser = true;

      this.$store
        .dispatch("user/logoutAsOtherUser")
        .then(() => {
          const redirectPath = this.$route.query.redirect || "/";
          this.$router.push(redirectPath).then(() => {
            this.$router.go().then(() => {
              this.fetchMe();
              this.fetchOverallStatus();
              this.fetchAllUserTasks();
              this.interval = setInterval(
                () => this.fetchAllUserTasks(),
                60000
              );
            });
          });
        })
        .catch((error) => {
          if (error.graphQLErrors) {
            for (let err of error.graphQLErrors) {
              // show snackbar
              const payload = {
                color: "error",
                message: err.message,
              };
              this.$store.dispatch("snackbar/showMessage", payload);
            }
          }
        })
        .finally(() => {
          this.isLoggingOutAsOtherUser = false;
          this.loggedOutAsOtherUserCounter += 1;
        });
    },

    logout() {
      this.$store
        .dispatch("user/logout")
        .then(() => {
          this.drawer = false;
          this.$router.push("/login");
        })
        .catch((error) => {
          console.log("Logout failed", error);
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
	"exporting": "Exporting",
	"exportDone": "Export done",
	"loggedInAs": "Logged in as"
  },
  "nl": {
	"exporting": "Exporteren",
	"exportDone": "Export voltooid",
	"loggedInAs": "Ingelogd als"
  },
  "de": {
	"exporting": "Exportieren",
	"exportDone": "Export abgeschlossen",
	"loggedInAs": "Eingeloggt als"
  }
}
</i18n>
